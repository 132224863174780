import React, { useState, useEffect } from "react";
import { getAccessTokenApi, logout, decodedToken } from "../api/auth";
import { verificaSesion } from "../api/user";

export const AuthContext = React.createContext();

export default function AuthProvider(props) {
  const { children } = props;

  const [usuario, setUsuario] = useState({
    usuario: null,
    isLoading: true,
    rol: null,
    periodos: null
  });

  useEffect(() => {
    checkUserLogin(setUsuario);
  }, []);

  return <AuthContext.Provider value={usuario}>{children}</AuthContext.Provider>;
}

/**
 * Revisa si el usuario esta logueado
 * Si los token estan bien estructurados, decodifica el valor del token de acceso y devuelve sus valores: {id, name, lastname, username, role}
 * @param {Function} setUser
 */
async function checkUserLogin(setUser) {
  const accessToken = getAccessTokenApi();

  if (!accessToken) {
    setUser({
      usuario: null,
      isLoading: false,
      rol: null,
      periodos: null
    });
    logout();
  } else {
    const usuario = decodedToken(accessToken);
    setUser({
      isLoading: false,
      usuario: usuario,
      rol: usuario?.rol,
      periodos: usuario?.periodos
    });
    await verificaSesion().catch(() => {
      logout();
    });
  }
}
