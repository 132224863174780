import { useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { Redirect } from "react-router-dom";
import { getAccessTokenApi } from "../../api/auth";
import { CONST_ACCESS_TOKEN } from "../../utils/shared";
import { login } from "../../api/user";

function Login() {
  const [passwordType, setPasswordType] = useState("password");
  const [isLogging, setIsLogging] = useState(false);

  if (getAccessTokenApi()) {
    return <Redirect to="/app" />;
  }

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  // ------ Conecta con endpoint ------
  const sendDataToServer = async (data) => {
    try {
      setIsLogging(true);
      const response = await login(data);
      setIsLogging(false);
      localStorage.setItem(CONST_ACCESS_TOKEN, response.payload.token);

      message.success("¡Bienvenido!");
      window.location.href = "/app";
    } catch (err) {
      message.error(err.message);
    } finally {
      setIsLogging(false);
    }
  };

  const onFinishFailed = ({ values }) => {
    if (Number(values.documentoregistro) < 1) {
      message.error("Número de DNI no válido");
    }
  };

  /**
   * showPassword
   * @param {Boolean} isChecked
   */
  const showPassword = (isChecked) => {
    if (isChecked) {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <div className="max-w-xs mx-auto">
      <h1 className="text-2xl font-bold my-8 text-center">Inicia sesión</h1>

      <Form
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="DNI o usuario"
          name="dniorusuario"
          rules={[
            {
              required: true,
              message: "El DNI o usuario es necesario"
            },
            { min: 3, message: "La cantidad mínima de caracteres debe ser 3" }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Contraseña"
          name="password"
          rules={[{ required: true, message: "La contraseña es necesaria" }]}
        >
          <Input type={passwordType} />
        </Form.Item>

        <Checkbox onClick={(e) => showPassword(e.target.checked)}>Mostrar contraseña</Checkbox>

        <Form.Item>
          <Button type="primary" block className="mt-8" loading={isLogging} htmlType="submit">
            Iniciar sesión
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
