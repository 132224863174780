import { Switch, Button } from "antd";
import { EditOutlined, DeleteOutlined, SafetyCertificateOutlined } from "@ant-design/icons";

/**
 * createColumns
 * Complemento para mostrar las tablas tanto como en antd y modo responsive
 * @param {Function} showEditModal
 * @param {Function} showDeleteModal
 * @param {Number} page
 */
export function createColumns(
  page = 1,
  showEditModal = null,
  onChangeState = null,
  showDeleteModal = null,
  onChangeInscripcionActivo = null,
  onTestVocacionalActivo = null,
  onChangeisEnabledOnSistema = null,
  onGenerateCodesMassively = null
) {
  return [
    {
      title: "N.º",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + index + 1
    },
    {
      title: "Denominación",
      dataIndex: "denominacion",
      key: "denominacion",
      sorter: (a, b) => a.denominacion.localeCompare(b.denominacion)
    },
    {
      title: "ID",
      dataIndex: "idperiodo",
      key: "idperiodo",
      sorter: (a, b) => a.idperiodo.localeCompare(b.idperiodo),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Referencia",
      dataIndex: "referencia",
      key: "referencia",
      sorter: (a, b) => a.referencia.localeCompare(b.referencia),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Año",
      dataIndex: "anio",
      key: "anio",
      sorter: (a, b) => a.anio.localeCompare(b.anio),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Fecha cierre",
      dataIndex: "labelfechafinalizacion",
      key: "labelfechafinalizacion",
      sorter: (a, b) => a.labelfechafinalizacion.localeCompare(b.labelfechafinalizacion),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Institución",
      dataIndex: "institucion",
      key: "institucion",
      sorter: (a, b) => a.institucion.localeCompare(b.institucion),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Inscripcion",
      key: "inscripcionactivo",
      render: (record) => (
        <span>
          <Switch
            size="small"
            checked={record.inscripcionactivo}
            onClick={() => onChangeInscripcionActivo(record)}
          />
        </span>
      )
    },
    {
      title: "Test VPI",
      key: "testactivo",
      render: (record) => (
        <span>
          <Switch
            size="small"
            checked={record.testactivo}
            onClick={() => onTestVocacionalActivo(record)}
          />
        </span>
      )
    },
    {
      title: "Sistema",
      key: "sistema",
      render: (record) => (
        <span>
          <Switch
            size="small"
            checked={record.sistema}
            onClick={() => onChangeisEnabledOnSistema(record)}
          />
        </span>
      )
    },
    {
      title: "Acciones",
      key: "action",
      render: (record) => (
        <span>
          <Button icon={<EditOutlined />} onClick={() => showEditModal(record)} />
          &nbsp;
          <Button icon={<DeleteOutlined />} onClick={() => showDeleteModal(record)} />
          &nbsp;
          <Button
            icon={<SafetyCertificateOutlined />}
            onClick={() => onGenerateCodesMassively(record)}
          />
          &nbsp;
          <Switch size="small" checked={record.activo} onClick={() => onChangeState(record)} />
        </span>
      )
    }
  ];
}
