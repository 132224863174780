import { Fragment, useState, useEffect } from "react";
import { Button, message, Modal, Radio } from "antd";
import { TitleHeader, Searcher, TableAntd } from "../../components";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { createColumns } from "./formatTable";
import * as AppApi from "../../api/sistema";
import AddEditPeriodo from "./PeriodoAddEdit";
import DeletePeriodo from "./PeriodoDelete";

function Periodo() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [dataToTable, setDataToTable] = useState([]);
  const [filterTable, setFilterTable] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [valueActivo, setValueActivo] = useState(1);

  const fetchDataToTable = async () => {
    setIsLoading(true);

    AppApi.executeQuery({
      query: "get_all_periodos"
    })
      .then((data) => {
        const item = data.payload;
        setDataToTable(item);
        setTotalData(item.length);

        const tmpFiltered = item.filter((o) => o.activo === 1);
        setFilterTable(tmpFiltered);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDataToTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const showAddModal = () => {
    setIsModalVisible(true);
    setModalTitle("Agregar examen");
    setModalContent(<AddEditPeriodo record={null} closeModal={closeModal} />);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const showEditModal = (periodo) => {
    setIsModalVisible(true);
    setModalTitle("Editar examen");
    setModalContent(<AddEditPeriodo record={periodo} closeModal={closeModal} />);
  };

  // ------ Show modal delete record -----------
  const onChangeState = (record) => {
    const activateMsg = record.activo ? "DESACTIVO" : "ACTIVO";

    Modal.confirm({
      title: `¿Estás seguro que deseas cambiar el estado a  ${activateMsg}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, " + activateMsg,
      cancelText: "Cancelar",
      onOk() {
        const params = {
          nombretabla: "pos_periodo",
          nombreid: "idperiodo",
          valorid: record?.idperiodo
        };

        AppApi.executeUpdate(params, {
          activo: !record.activo
        })
          .then((res) => {
            message.success(res.message);
            setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            message.error(err.message);
          });
      },
      onCancel() {
        return null;
      }
    });
  };

  // ------ Show modal change inscripcion -----------
  const onChangeInscripcionActivo = (record) => {
    const activateMsg = record.inscripcionactivo ? "DESACTIVO" : "ACTIVO";

    Modal.confirm({
      title: `¿Estás seguro que deseas cambiar la inscripcion a ${activateMsg}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, " + activateMsg,
      cancelText: "Cancelar",
      onOk() {
        const params = {
          nombretabla: "pos_periodo",
          nombreid: "idperiodo",
          valorid: record?.idperiodo
        };

        AppApi.executeUpdate(params, {
          inscripcionactivo: !record.inscripcionactivo
        })
          .then((res) => {
            message.success(res.message);
            setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            message.error(err.message);
          });
      },
      onCancel() {
        return null;
      }
    });
  };

  // ------ Cambio test vocacional -----------
  const onTestVocacionalActivo = (record) => {
    const activateMsg = record.testactivo ? "DESACTIVO" : "ACTIVO";

    Modal.confirm({
      title: `¿Estás seguro que deseas cambiar la inscripcion a ${activateMsg}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, " + activateMsg,
      cancelText: "Cancelar",
      onOk() {
        const params = {
          nombretabla: "pos_periodo",
          nombreid: "idperiodo",
          valorid: record?.idperiodo
        };

        AppApi.executeUpdate(params, {
          testactivo: !record.testactivo
        })
          .then((res) => {
            message.success(res.message);
            setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            message.error(err.message);
          });
      },
      onCancel() {
        return null;
      }
    });
  };

  // ------ Change habilitado sistema -----------
  const onChangeisEnabledOnSistema = (record) => {
    const activateMsg = record.sistema ? "Desabilitado" : "Habilitado";

    Modal.confirm({
      title: `¿Estás seguro que deseas cambiar la inscripcion a ${activateMsg}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, " + activateMsg,
      cancelText: "Cancelar",
      onOk() {
        const params = {
          nombretabla: "pos_periodo",
          nombreid: "idperiodo",
          valorid: record?.idperiodo
        };

        AppApi.executeUpdate(params, {
          sistema: !record.sistema
        })
          .then((res) => {
            message.success(res.message);
            setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            message.error(err.message);
          });
      },
      onCancel() {
        return null;
      }
    });
  };

  // ------ Genera codigos masivamente -----------
  const onGenerateCodesMassively = (record) => {
    Modal.confirm({
      title: `¿Estás seguro que deseas generar códigos masivamente a todos los postulantes inscritos?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, Generar",
      cancelText: "No, Cancelar",
      onOk() {
        AppApi.executePost("/api/core/genera-codigo-masivo", {
          idperiodo: record?.idperiodo
        })
          .then((res) => {
            message.success(res.message);
            setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            message.error(err.message);
          });
      },
      onCancel() {
        return null;
      }
    });
  };

  const showDeleteModal = (usuario) => {
    setIsModalVisible(true);
    setModalTitle("Eliminar periodo");
    setModalContent(<DeletePeriodo registro={usuario} closeModal={closeModal} />);
  };

  const search = (value) => {
    const tempFilterTable = dataToTable.filter((o) =>
      Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.toLowerCase()))
    );

    setFilterTable(tempFilterTable);
  };

  const onChangeActivo = ({ target: { value } }) => {
    setValueActivo(value);

    const tmpFiltered = dataToTable.filter((o) => o.activo === value);
    setFilterTable(tmpFiltered);
  };

  const columnsToTable = createColumns(
    page,
    showEditModal,
    onChangeState,
    showDeleteModal,
    onChangeInscripcionActivo,
    onTestVocacionalActivo,
    onChangeisEnabledOnSistema,
    onGenerateCodesMassively
  );

  const optionsWithDisabled = [
    {
      label: "Activos",
      value: 1
    },
    {
      label: "Desactivos",
      value: 0
    }
  ];

  return (
    <Fragment>
      <TitleHeader
        title={
          <span className="flex gap-2 items-center">
            <h1 className="font-bold">Exámenes ({totalData})</h1>
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => setReload(!reload)}
            ></Button>
          </span>
        }
        buttonTitle="Nuevo examen"
        onButtonTitleHeaderClick={showAddModal}
      />

      <Fragment>
        <div className="flex w-full justify-between">
          <div className="w-full md:w-2/3 lg:w-4/5">
            <Searcher onSearch={search} placeholder="Busca un examen" />
          </div>
          <div className="w-full md:w-1/3 lg:w-1/5">
            <Radio.Group
              options={optionsWithDisabled}
              onChange={onChangeActivo}
              value={valueActivo}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </div>
      </Fragment>

      <TableAntd
        columns={columnsToTable}
        dataSource={filterTable == null ? dataToTable : filterTable}
        isLoading={isLoading}
        total={totalData}
        setPageSize={setPageSize}
        setPage={setPage}
        pageSize={pageSize}
      />

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        maskClosable={false}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
}

export default Periodo;
