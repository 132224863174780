import { CONST_ACCESS_TOKEN } from "../utils/shared";
import jwt from "jsonwebtoken";
import * as UserApi from "./user";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

/**
 * Obtiene token de acceso, devuelve el String o null
 */
export const getAccessTokenApi = () => {
  const accessToken = localStorage.getItem(CONST_ACCESS_TOKEN);

  if (!accessToken || accessToken === "null") return null;

  return tokenIsValid(accessToken) ? accessToken : null;
};

/**
 * Decodifica el Token
 * @param {String} token
 */
export const decodedToken = (token) => {
  try {
    return jwt.decode(token, SECRET_KEY);
  } catch (err) {
    if (err) {
      return false;
    }
  }
};

/**
 * Verifica si es un token válido
 * @param {String} token
 * @returns {Boolean}
 */
const tokenIsValid = (token) => {
  if (!token) {
    return {
      err: "No token provided"
    };
  }

  try {
    const decoded = jwt.verify(token, SECRET_KEY);
    if (decoded) {
      return true;
    }
  } catch (err) {
    return false;
  }
};

/**
 * Funcion para desloguear de la aplicación
 */
export const logout = async () => {
  if (ACCESS_TOKEN) {
    await UserApi.logout();
    localStorage.removeItem(CONST_ACCESS_TOKEN);
    localStorage.clear();
    window.location.reload();
  }
};

// Token ejecutado
export const ACCESS_TOKEN = getAccessTokenApi();
