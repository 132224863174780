/**
 * USER API
 * Funciones para manejar peticiones con el servidor
 */

import * as HTTP_REQUEST from "./api";
import { handleResponse } from "../utils/functions";

const LOGIN_USER = "/api/auth/personal/login";
const LOGOUT_USER = "/api/auth/personal/logout";
const PATH_MODEL = "/api/auth/personal";
const VERIFICA_SESION = "/api/auth/personal/verificar-sesion";

export const login = async (body) => {
  const response = await HTTP_REQUEST.post(LOGIN_USER, body);
  return handleResponse(response);
};

export const logout = async () => {
  const response = await HTTP_REQUEST.post(LOGOUT_USER);
  return handleResponse(response);
};

export const verificaSesion = async () => {
  const response = await HTTP_REQUEST.get(VERIFICA_SESION);
  return handleResponse(response);
};

export const getAll = async () => {
  const response = await HTTP_REQUEST.get(PATH_MODEL);
  return handleResponse(response);
};

export const addOne = async (body) => {
  const response = await HTTP_REQUEST.post(PATH_MODEL, body);
  return handleResponse(response);
};

export const deleteOne = async (userId) => {
  const response = await HTTP_REQUEST.delt(PATH_MODEL, userId);
  return handleResponse(response);
};

export const updateOne = async (body, userId) => {
  const response = await HTTP_REQUEST.put(PATH_MODEL, body, userId);
  return handleResponse(response);
};

// reset password personal
export const resetPassword = async (body, userId) => {
  const response = await HTTP_REQUEST.put(PATH_MODEL + "/password", body, userId);
  return handleResponse(response);
};

export const changePassword = async (body, userId) => {
  const response = await HTTP_REQUEST.put(PATH_MODEL + "/change-password", body, userId);
  return handleResponse(response);
};

export const getOne = async (userId) => {
  const response = await HTTP_REQUEST.get(PATH_MODEL + "/" + userId);
  return handleResponse(response);
};
