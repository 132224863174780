import * as HTTP_REQUEST from "./api";
import { handleResponse } from "../utils/functions";
import { ACCESS_TOKEN } from "../api/auth";

const SISTEMA_PATH = "/api/core/panel";

export const executeQuery = async (body) => {
  const response = await HTTP_REQUEST.post(`${SISTEMA_PATH}/execute`, body);
  return handleResponse(response);
};

export const getPersonas = async (query) => {
  const response = await HTTP_REQUEST.get(`${SISTEMA_PATH}/personas${query}`);
  return handleResponse(response);
};

export const getPostulantes = async (body) => {
  const response = await HTTP_REQUEST.post(`${SISTEMA_PATH}/postulantes`, body);
  return handleResponse(response);
};

export const executeCreate = async ({ nombretabla }, data) => {
  const response = await HTTP_REQUEST.post(`${SISTEMA_PATH}/add/${nombretabla}`, data);
  return handleResponse(response);
};

export const executeUpdate = async ({ nombretabla, nombreid, valorid }, body) => {
  const response = await HTTP_REQUEST.put(
    `${SISTEMA_PATH}/${nombretabla}/${nombreid}/${valorid}`,
    body
  );
  return handleResponse(response);
};

export const previewFile = async (rutaArchivo) => {
  const response = await HTTP_REQUEST.getFile(
    `${SISTEMA_PATH}/view${rutaArchivo}?token=${ACCESS_TOKEN}`
  );
  return response; // return a blob
};

export const executePost = async (action, data) => {
  const response = await HTTP_REQUEST.post(`${action}`, data);
  return handleResponse(response);
};

export const executeDelete = async (action, id) => {
  const response = await HTTP_REQUEST.delt(action, id);
  return handleResponse(response);
};

// Obtiene el excel file
export const reportQuery = async (data) => {
  const response = await HTTP_REQUEST.postFile(`${SISTEMA_PATH}/reporte`, data);
  return response; // return a blob
};

export const recortarFoto = async (data) => {
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch("https://admision.unjbg.edu.pe:8444/recortar", params);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const resData = await response.json();
    return resData;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const quitaFondoFoto = async (data) => {
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch("https://admision.unjbg.edu.pe:8444/quitar_fondo", params);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const resData = await response.json();

    return resData;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const restablecerFoto = async (data) => {
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch("https://admision.unjbg.edu.pe:8444/restablecer", params);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const resData = await response.json();

    return resData;
  } catch (error) {
    console.error("Error:", error);
  }
};
