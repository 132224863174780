import { useState, useEffect } from "react";
import { Form, Input, message, Button, Select } from "antd";
import * as AppApi from "../../api/sistema";
import { getPeriodo } from "../../utils/functions";

const idPeriodo = getPeriodo()?.idperiodo || 0;

const { Option } = Select;

const DatosPersonales = ({ record, isDisabled }) => {
  const [catalogo, setCatalogo] = useState({
    formapago: [],
    programas_estudio: [],
    modalidadpago: []
  });
  const [informacionPago, setInformacionPago] = useState({
    pagado: false,
    fecha: "",
    nombre: "",
    importe: "",
    medio: ""
  });
  const [inputInformacionPago, setInputInformacionPago] = useState({
    numerocomprobante: record.numerocomprobante,
    documentoregistrocomprobante: record.documentoregistrocomprobante,
    concepto: record.idmodalidadpago
  });

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  useEffect(() => {
    fetchCatalogo();
    fetchDataToPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataToPage = async () => {
    try {
      const res3 = await AppApi.executeQuery({
        query: "get_detalle_pago_postulante",
        variables: [
          {
            name: "CONCEPTO",
            value: String(record.idconcepto).padStart(8, "0"),
            type: "string"
          },
          { name: "NUMERODOCUMENTO", value: record.documentoregistrocomprobante, type: "string" },
          {
            name: "SECUENCIA",
            value: String(record.numerocomprobante).padStart(7, "0"),
            type: "string"
          }
        ]
      });

      setInformacionPago({
        pagado: Boolean(res3.payload.length),
        fecha: res3.payload[0]?.fechapago,
        nombre: res3.payload[0]?.nombre,
        importe: res3.payload[0]?.importe,
        banco: res3.payload[0]?.banco
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCatalogo = async () => {
    const res1 = await AppApi.executeQuery({ query: "get_forma_pago" });
    const res2 = await AppApi.executeQuery({ query: "get_all_programas_estudio" });
    const res3 = await AppApi.executeQuery({
      query: "get_modalidad_pago",
      variables: [
        {
          name: "IDPERIODO",
          value: idPeriodo
        }
      ]
    });

    setCatalogo({
      formapago: res1.payload,
      programas_estudio: res2.payload,
      modalidadpago: res3.payload
    });
  };

  // ------- Conexion con api -------
  const sendDataToServer = async (data) => {
    const params = {
      nombretabla: "pos_postulante",
      nombreid: "idpostulante",
      valorid: record?.idpostulante
    };

    AppApi.executeUpdate(params, data)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        <Form.Item label="Forma de pago" name="idformapago" initialValue={record?.idformapago}>
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.formapago.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="DNI comprobante"
          name="documentoregistrocomprobante"
          initialValue={record?.documentoregistrocomprobante}
        >
          <Input
            onChange={(e) =>
              setInputInformacionPago({
                ...inputInformacionPago,
                documentoregistrocomprobante: e.target.value
              })
            }
            disabled={isDisabled}
          />
        </Form.Item>

        <Form.Item
          label="Número de comprobante"
          name="numerocomprobante"
          initialValue={record?.numerocomprobante}
        >
          <Input
            onChange={(e) =>
              setInputInformacionPago({
                ...inputInformacionPago,
                numerocomprobante: e.target.value
              })
            }
            disabled={isDisabled}
          />
        </Form.Item>

        <Form.Item
          label="Fecha comprobante"
          name="fechacomprobante"
          initialValue={record?.fechacomprobante}
        >
          <Input type="date" disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Concepto de pago"
          name="idmodalidadpago"
          initialValue={record?.idmodalidadpago || "1"}
        >
          <Select
            disabled={isDisabled}
            onSelect={(value) => {
              setInputInformacionPago({
                ...inputInformacionPago,
                concepto: value.padStart(8, "0")
              });
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.modalidadpago.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="form-group md:col-span-2">
          <Form.Item
            label="Especialidad a la que postula"
            name="idprogramaestudio"
            initialValue={record?.idprogramaestudio || null}
          >
            <Select disabled={isDisabled}>
              <Option value="" disabled>
                Seleccione
              </Option>
              {catalogo.programas_estudio.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="form-group md:col-span-2">
          <Form.Item
            label="Otra especialidad en la que estaría interesado"
            name="idprogramaestudiosegundo"
            initialValue={record?.idprogramaestudiosegundo || null}
          >
            <Select disabled={isDisabled}>
              <Option value="" disabled>
                Seleccione
              </Option>
              {catalogo.programas_estudio.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <hr className="mt-4" />
      <h1 className="text-azul-700 font-semibold text-base my-4">Datos de pago</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div>
          <p>
            PAGADO:
            {informacionPago.pagado ? (
              <span className="text-azul-700 font-bold"> SÍ</span>
            ) : (
              <span className="text-rojo-500 font-bold"> NO</span>
            )}
          </p>
        </div>
      </div>

      <div className="flex gap-4">
        <div>
          <p>
            FECHA: <span className="font-bold">{informacionPago.fecha}</span>
          </p>
        </div>

        <div>
          <p>
            NOMBRE: <span className="font-bold">{informacionPago.nombre}</span>
          </p>
        </div>

        <div>
          <p>
            IMPORTE: <span className="font-bold">S/{informacionPago.importe}</span>
          </p>
        </div>

        <div>
          <p>
            MEDIO: <span className="font-bold">{informacionPago.banco}</span>
          </p>
        </div>
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DatosPersonales;
